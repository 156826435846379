#main-menu {
    &.homepage-menu {
        @include media-breakpoint-up('xl') {
            position: fixed;
            width: 100%;
            transition: .2s;

            &:before {
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                position: absolute;
                transition: .2s;
            }

            &:not(.active) {
                background: rgba($oyat-white, 0);
                box-shadow: 0 0 rgba(black, 0) !important;

                &:before {
                    z-index: -10;
                    height: calc(100% + 50px);
                    background: rgb(255, 255, 255);
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 20%, rgba(255, 255, 255, 0.7) 60%, rgba(255, 255, 255, 0.7) 100%);
                    opacity: 1;
                }
            }
        }
    }
}

.homepage {
    #banner {
        position: relative;
        height: 100vh;

        @include media-breakpoint-down(lg) {
            height: 70vh;
        }

        @include media-breakpoint-down(md) {
            height: 60vh;
        }

        @include media-breakpoint-down(xs) {
            height: 50vh;
        }

        .banner-img {
            height: 100vh;
            object-fit: cover;

            @include media-breakpoint-down(lg) {
                height: 70vh;
            }

            @include media-breakpoint-down(md) {
                height: 60vh;
            }

            @include media-breakpoint-down(xs) {
                height: 50vh;
            }
        }

        img {
            width: 100%;
        }

         &:before {
             content: '';
             position: absolute;
             bottom: 0;
             left: 0;
             width: 100%;
             height: 50%;
             background: transparent linear-gradient(180deg, #00000000 0%, #000 100%) 0% 0% no-repeat padding-box;
         }

        .made-in-france {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            max-height: 85vh;
            width: 100%;
            z-index: 100;
            display: flex;
            align-items: end;

            @include media-breakpoint-down(lg) {
                height: 60vh;
            }

            @include media-breakpoint-down(md) {
                height: 50vh;
            }

            @include media-breakpoint-down(xs) {
                text-align: center;
                height: 40vh;
            }

            img {
                width: 350px;
            }
        }
    }

    #intro {
        h1 {
            font-size: 30px;
            font-weight: $weightRegular;
        }
    }

    #products .slick-product-carousel {

        .card-image a {
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }

        .name {
            color: $main-color;
        }

        @media screen and (min-width: 1600px) {
            .card-image {
                width: 395px !important;
            }
        }

        .card-image .image {
            box-shadow: none;
        }

        .slick-list {
            padding: 0 !important;
        }

        @include media-breakpoint-up(xl) {
            .slick-slide {
                text-align: center;
            }

            .slick-slide:not(.slick-current) {
                .card-image {
                    transform: scale(0.75);
                }
            }
        }

        @include media-breakpoint-down(lg) {
            .slick-slide {
                padding: 0 20px;
            }
        }

        .slick-prev {
            @include media-breakpoint-up(xl) {
                left: 0;
            }
        }

        .slick-next {
            @include media-breakpoint-up(xl) {
                right: 0;
            }
        }

        &.less-three {
            @include media-breakpoint-up(xl) {
                [data-slick-index="1"] {
                    .card-image {
                        transform: scale(1) !important;
                    }
                }
                .slick-slide:not([data-slick-index="1"])  {
                    .card-image {
                        transform: scale(0.75) !important;
                    }
                }
            }
        }
    }

    #collections {
        background-color: $main-color-light;
    }

    #recipe .slick-recipe-carousel {
        @include media-breakpoint-up(xl) {
            .slick-slide {
                padding: 0 30px;
            }
        }
        @include media-breakpoint-down(lg) {
            .slick-slide {
                padding: 0 20px;
            }
        }
    }

    #products .slick-product-carousel,
    #recipe .slick-recipe-carousel  {
        .slick-arrow {
            z-index: 10;
        }

        .slick-prev {
            &:before {
                content: url("/public/images/icons/chevron-left.png");
            }
        }

        .slick-next {
            &:before {
                content: url("/public/images/icons/chevron-right.png");
            }
        }

        .slick-dots {
            bottom: -40px;
        }

        .slick-dots li button::before {
            font-size: 10px;
            color: $main-color;
        }
    }

    #myModal {
        .modal-header {
            border: none;
        }

        .modal-body {
            @include media-breakpoint-up('lg') {
                background-image: url("../../../../../public/images/homepage/brasero_modal.png");
                background-repeat: no-repeat;
                background-position: 100% 100%;
                background-size: 45%;
            }

            .modal-title {
                font-weight: $weightSemiBold;
                text-transform: uppercase;
            }

            .modal-subtitle {
                font-weight: $weightSemiBold;
                font-size: 20px;
            }

            .modal-livraison {
                letter-spacing: 2px;
                text-transform: uppercase;
                font-weight: $weightLight;
            }
        }
    }
}
