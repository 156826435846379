//@import '../../../BootstrapTheme/assets/scss/index';

// Third party components
@import '~semantic-ui-css/components/flag.min.css';
@import '~glightbox/dist/css/glightbox.min.css';

@import 'imports';
@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';
@import '~aos/dist/aos';
@import 'pages/homepage';
